import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import Price from 'shopper/components/Price';
import Text from 'shopper/components/Text';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import { getOfferImageLink, toOfferImageAltText } from 'lib/image';
import { toOfferLink } from 'lib/links';
import { toFormattedOfferOldPrice, toFormattedOfferPrice } from 'lib/offer';
import { noop } from 'lib/utils';

const OffersListItemCard = ({
  className,
  offerOldPrice,
  offerPhoto,
  offerPrice,
  offerPriceType,
  offerSlug,
  offerTitle,
  onOffersItemClick = noop,
  'data-test-selector': dataTestSelector,
}) => (
  <NextLink href={toOfferLink(offerSlug)} prefetch={false} passHref>
    <Anchor
      className={twMerge(
        'flex w-full items-start border-b border-solid border-neutral-high-200 py-4 dark:border-neutral-low-300',
        className
      )}
      data-test-selector={dataTestSelector}
      onClick={onOffersItemClick}
    >
      <div className="mr-4 flex min-w-20 overflow-hidden rounded-2">
        <Image
          alt={toOfferImageAltText(offerTitle)}
          height={80}
          src={getOfferImageLink(offerPhoto, 80)}
          width={80}
        />
      </div>
      <div>
        <Text className="mb-1" size="size2">
          {offerTitle}
        </Text>
        <Price
          primaryInfo={toFormattedOfferPrice(offerPrice, offerPriceType)}
          secondaryInfo={toFormattedOfferOldPrice(
            offerOldPrice,
            offerPriceType
          )}
          size="size3"
        />
      </div>
    </Anchor>
  </NextLink>
);

OffersListItemCard.propTypes = {
  offerOldPrice: PropTypes.number,
  offerPhoto: PropTypes.string.isRequired,
  offerPrice: PropTypes.number.isRequired,
  offerSlug: PropTypes.string.isRequired,
  offerTitle: PropTypes.string.isRequired,
  onOffersItemClick: PropTypes.func,
};

export default OffersListItemCard;
